html {
  font-size: 100%;
  -ms-overflow-style: -ms-autohiding-scrollbar;
}

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Helvetica, Arial, sans-serif;
  font-size: 1rem;
  line-height: 1.5;
}

button:disabled {
  opacity: 0.5;
}

h1, h2, h3, h4 { position: relative; color: #1a237e; font-weight: 300 !important;  }
main .page-content>h1 { color: #fff; }
main .page-content>h2 { color: #fff; font-size: 1.75rem !important; }
main .page-content .divider-white { width: 100% !important; }

.header-banner h2 { color: #fff !important; }
.header-banner h5 { color: #ccc !important; }


.side-menu {
  padding: 0 !important;
}
.Select-control {
  height: 30px !important;
}
.Select-control .Select-placeholder {
  line-height: 20px !important;
}
.Select-control .Select-value {
  line-height: 26px !important;
}
.Select-control .Select-value .Select-value-label {
  line-height: 26px !important;
}
.Select-control .Select-input {
  height: 26px !important;
}



.__floater__body h4 {
  text-align: left;
  margin: 0px 0px 10px 9px !important;
}
.__floater__body > div > div div {
  text-align: left;
  padding: 20px 9px !important;
}

.sidebar-drawer {
  z-index: 90 !important;
}


/**
 * Search
 */
.search-form {
  position: relative;
}
.search-form nav {
  left: 72px !important;
  top: 64px;
}
.open-sidebar .search-form nav {
  left: 240px !important;
}
.search-form .form-wrap {
  position: relative;
  display: block;
  height: 35px;
}
.search-form .form-wrap-mobile {
  position: relative;
  display: none;
  height: 35px;
}
.search-form .mobile-trigger{
  display: none;
  height: 35px;
  cursor: pointer;
}
#root>div:not(.open-sidebar) .search-form .form-wrap {
  display: none;
}
#root>div:not(.open-sidebar) .search-form .form-wrap-mobile,
#root>div:not(.open-sidebar) .search-form .mobile-trigger {
  display: block;
}
#root>div:not(.open-sidebar) .search-form .form-wrap-mobile input {
  padding-left: 16px;
}

@media(max-width: 600px) {
  .search-form nav {
    left: 56px !important;
    top: 56px;
  }
}


/**
 * Loading Indicator
 */
#loadingIndicator {
  left: 72px !important;
  z-index: 99 !important;
}
.open-sidebar #loadingIndicator {
  left: 240px !important;
  z-index: 1000 !important;
}

@media(max-width: 600px) {
  #loadingIndicator {
    left: 56px !important;
  }
}

#loadingIndicator.initializing {
  left: 0 !important;
}
#loadingIndicator.initializing h6 {
  color: #999 !important;
}
#loadingIndicator.initializing svg {
  color: #ddd !important;
}

.main-content-bg {
  z-index: -1;
  left: 0;
  width: 100%;
  height: 300px;
  background-color: #484d9c;
}
.main-content-bg::after {
  content: '';
  position: absolute;
  top: 295px;
  left: 0;
  width: 100%;
  height: 100%;
  -webkit-transform-origin: 100% 0;
  -ms-transform-origin: 100% 0;
  transform-origin: 100% 0;
  -webkit-transform: skew(277deg);
  -ms-transform: skew(277deg);
  transform: skew(277deg);
  z-index: -1;
  background-color: #484d9c;


  -webkit-box-shadow: 10px 75px 250px 15px rgba(0,0,0,0.5);
  -moz-box-shadow: 10px 75px 250px 15px rgba(0,0,0,0.5);
  box-shadow: 10px 75px 250px 15px rgba(0,0,0,0.5);
}
.main-footer-bg {
  z-index: -1;
  left: 0;
  width: 100%;
  height: 150px;
}
.main-footer-bg::before- {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #fafafa;
  -webkit-transform-origin: 100% 0;
  -ms-transform-origin: 100% 0;
  transform-origin: 100% 0;
  -webkit-transform: skew(97deg);
  -ms-transform: skew(97deg);
  transform: skew(97deg)
}




.main-content-bg2 {
  z-index: -1;
  left: 0;
  width: 100%;
  height: 500px;
  background-color: #eee;
}
.main-content-bg2::after {
  content: '';
  position: absolute;
  top: 500px;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #eee;
  -webkit-transform-origin: 100% 0;
  -ms-transform-origin: 100% 0;
  transform-origin: 100% 0;
  -webkit-transform: skew(275deg);
  -ms-transform: skew(275deg);
  transform: skew(275deg);
  z-index: -1;
}



.parallax-outer {
  position: absolute;
  z-index: -1;
  left: 0;
  right: 0;
  top: 0;
}


.divider-white {
  background-color: rgba(255, 255, 255, 0.12) !important;
}


.inline-actions {
  position: absolute;
  right: 8px;
  top: 8px;
  z-index: 1;
}
.inline-actions .sticky-visible {
  display: none !important;
}
.sticky .inline-actions .sticky-visible {
  display: inline-block !important;
}



/**
 * Freshdesk
 */
#freshworks-container {
  z-index: 100 !important;
  position: fixed;
}
#freshwidget-close {
  display: none !important;
}
.modal-panel-active .modal-body.feedback-footer .btn, .modal-panel-active .modal-footer.feedback-footer .btn,
#helpdesk_ticket_submit {
  background-color: #1a237e !important;
  border-width: 1px !important;
}
#helpdesk_ticket_submit:hover {
  background-color: #1a237e !important;
  border-width: 1px !important;
}
.feedback-wrapper .feedback-body .form-portal .controls {
  margin-left: 0 !important;
}
.freshwidget-dialog {
  position: absolute;
  padding: 0;
  left: 50%;
  margin: unset !important;
  top: 50% !important;
  z-index: 100;
  transform: translate(-50%, -50%); 
  animation: unset !important;
}
.freshwidget-dialog-box {
  animation: unset !important;
}

/**
 * Joyride
 */
.react-joyride__tooltip {
  padding: 16px !important;
}
.__floater__body > div > div:nth-child(2) div {
  padding: 0 !important;
}
.react-joyride__tooltip button[data-action="primary"] {
  background-color: #5c6bc0 !important;
}
.react-joyride__tooltip button[type="button"]:focus {
  outline: none !important;
}


/*
ItemsList
.ItemsList_list-item>div {
  position: relative;
  padding-top: 62px;
}
.ItemsList_list-item.expanded>div>div {
  min-height: 62px;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 99;
  background-color: rgb(255, 255, 255);
}
*/


#ItemsList .ItemsList_list-item  > .static-list > .field-row:first-child {
  margin-top: 12px !important;
}
#ItemsList .ItemsList_list-item  > .static-list > .field-row:nth-last-child(1) {
  margin-bottom: 12px !important;
}
#ItemsList .static-list {
  padding: 12px 24px;
}

#ItemsList .static-list .collapsable-fields .static-list {
  padding: 12px 24px;
}
#ItemsList .static-list .collapsable-fields.images .static-list {
  padding: 24px;
}
#ItemsList .static-list .collapsable-fields .static-list.empty {
  padding: 0 !important;
}
#ItemsList .static-list .collapsable-fields.first {
  margin-top: 12px !important;
}
#ItemsList .static-list .collapsable-fields:last-of-type {
  margin-bottom: -12px !important;
}

#ItemsList .ItemsList_list-item .sticky {
  border-bottom: solid 1px rgba(0, 0, 0, 0.12) !important;
}

#ItemsList .MuiTablePagination-spacer {
  order: -2;
}

#ItemsList-pagination-pageselect {
  flex-direction: row;
}
#ItemsList-pagination-pageselect #page-select-label {
  margin-right: 4px;
}
.MuiSelect-root.MuiInput-input {
  padding-left: 8px;
  font-size: 14px;
}
#ItemsList-pagination-pageselect .MuiTablePagination-selectRoot {
  margin-right: 16px;
}
.MuiTablePagination-actions {
  margin-left: 8px;
}

.collapsable-fields.seofields {
  margin-top: 12px !important;
}

#ItemsList.disabled .ItemsList_list-item,
#ItemsList.disabled .MuiTablePagination-root,
#ItemsList.disabled .MuiToolbar-root {
  opacity: .5;
}

#ItemsList #ItemsList-pagination-pageselect {
  position: unset;
}

/*
#ItemsList .virtual-list {
  border-left: solid 5px lightgreen;
}
#ItemsList .static-list {
  border-left: solid 5px red;
}
*/





/*
#langify {
  animation: fill .5s ease forwards 3.5s;
}
*/
#langify-logo-bg {
  width: 275px;
  height: 90px;
  position: absolute;
  z-index: 0;
}
#langify-logo-bg g {
  opacity: .1;
}
#langify-logo-bg g>path {
  outline-offset: 0;
  stroke: #000 !important;
  fill: transparent !important;
  stroke-width: 5;
}
#langify-logo-bg #icon path {
  stroke: #000 !important;
  fill: transparent !important;
  stroke-width: 11;
}



#langify-logo-cover {
  width: 275px;
  height: 90px;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 10;
  opacity: 0;
  -webkit-filter: drop-shadow( 0 0 25px rgba(0, 0, 0, .3));
  filter: drop-shadow( 0 0 25px rgba(0, 0, 0, .3));
  animation: fill 3s ease forwards .5s;
}
#langify-logo-cover #icon path {
  fill: #5393d4 !important; 
  stroke: #5393d4 !important; 
  stroke-width: 11;
  fill-opacity: 1; 
  fill-rule: nonzero; 
}



#langify-logo {
  width: 275px;
  height: 90px;
}

#langify-logo #icon path {
  fill: transparent !important; 
  fill-opacity: 1; 
  fill-rule: nonzero; 
  stroke: #5393d4 !important; 
  stroke-dasharray: 2003;
  stroke-dashoffset: 2003;
  stroke-width: 11;
  animation: icon-anim 3s ease forwards;
}

#langify-logo g {
  opacity: .5;
}
#langify-logo g>path {
  outline-offset: 0;
  stroke: #000 !important;
  fill: #000 !important;
  fill: transparent !important;
  stroke-width: 5;
}

#langify-logo g>path:nth-child(2) {
  stroke-dasharray: 160;
  stroke-dashoffset: 160;
  animation: logo-anim 3s ease forwards;
}

#langify-logo g>path:nth-child(3) {
  stroke-dasharray: 287;
  stroke-dashoffset: 287;
  animation: logo-anim 3s ease forwards .1s;
}

#langify-logo g>path:nth-child(4) {
  stroke-dasharray: 265;
  stroke-dashoffset: 265;
  animation: logo-anim 3s ease forwards .2s;
}

#langify-logo g>path:nth-child(5) {
  stroke-dasharray: 388;
  stroke-dashoffset: 388;
  animation: logo-anim 3s ease forwards .3s;
}

#langify-logo g>path:nth-child(6) {
  stroke-dasharray: 175;
  stroke-dashoffset: 175;
  animation: logo-anim 3s ease forwards .4s;
}

#langify-logo g>path:nth-child(7) {
  stroke-dasharray: 198;
  stroke-dashoffset: 198;
  animation: logo-anim 3s ease forwards .5s;
}

#langify-logo g>path:nth-child(8) {
  stroke-dasharray: 363;
  stroke-dashoffset: 363;
  animation: logo-anim 3s ease forwards .6s;
}


@keyframes logo-anim {
  25% {
    stroke-dashoffset: 0;
  }
  70% {
    stroke-dashoffset: 0;
    stroke-opacity: 1;
  }
  95% {
    stroke-dashoffset: 0;
  }
  100% {
    stroke-dashoffset: 0;
  }
}

@keyframes icon-anim {
  75% {
    stroke-dashoffset: 0;
  }
  100% {
    stroke-opacity: 0;
  }
}

@keyframes fill {
  25% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  to {
    opacity: 1;
  }
}





#langify-icon {
  width: 77px;
  height: 90px;
  margin-left: -24px;
}
#langify-icon #icon path {
  fill: transparent !important; 
  fill-opacity: 1; 
  fill-rule: nonzero; 
  stroke: #5393d4 !important; 
  stroke-dasharray: 2003;
  stroke-dashoffset: 2003;
  stroke-width: 10;
  animation: icon-anim 3s ease infinite;
}

#langify-icon-cover {
  width: 77px;
  height: 90px;
  position: absolute;
  left: -24px;
  z-index: 10;
  opacity: 0;
  -webkit-filter: drop-shadow( 0 0 25px rgba(0, 0, 0, .3));
  filter: drop-shadow( 0 0 25px rgba(0, 0, 0, .3));
  animation: fill 3s ease infinite;
}
#langify-icon-cover #icon path {
  fill: #5393d4 !important; 
  stroke: #5393d4 !important; 
  stroke-width: 11;
  fill-opacity: 1; 
  fill-rule: nonzero; 
}


#langify-icon-bg {
  width: 77px;
  height: 90px;
  position: absolute;
  z-index: 0;
  display: none;
}




.main-content-bg5 svg {
  position: absolute;
}


.main-content-bg3 {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' viewBox='0 0 2600 1100'%3E%3Cdefs%3E%3ClinearGradient id='a' x1='-153' y1='642.88' x2='3129.11' y2='642.88' gradientUnits='userSpaceOnUse'%3E%3Cstop offset='.1' stop-color='%235c6bc0'/%3E%3Cstop offset='.27' stop-color='%235c6bc0'/%3E%3Cstop offset='.63' stop-color='%231a237e'/%3E%3Cstop offset='.8' stop-color='%231a237e'/%3E%3C/linearGradient%3E%3ClinearGradient id='b' y1='264.94' x2='3129.11' y2='264.94' xlink:href='%23a'/%3E%3C/defs%3E%3Cpath d='M2019.51 188.57c17.85 4.08 35.64 13 52.49 29.43l528 635.74v-66.18L2127 218c-34.41-33.57-72.76-35.77-107.49-29.43z' fill='%231a237e' opacity='.3'/%3E%3Cpath d='M1945 193L0 714v386h2600V853.33L2072 218c-41-40-87.59-35.47-127-25z' fill='url(%23a)'/%3E%3Cpath d='M2308.31 91c-60.24 17.09-77.23 69.94-37.76 117.43l210.94 253.81c26.74 32.19 72.83 54 118.51 59.37V8.25z' fill='url(%23b)'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-color: transparent;
  position: relative;
  background-size: cover;
  background-size: 200% auto;
  background-position: calc(50% - 225px) calc(50% + 100px);
  padding-top: 75vh;
  transform: scaleX(-1) scaleY(-.9);
  opacity: .9;
}
.main-content-bg4 {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 2600 1440'%3E%3Cdefs%3E%3ClinearGradient id='a' x1='862.49' y1='-420.67' x2='1546.24' y2='981.21' gradientUnits='userSpaceOnUse'%3E%3Cstop offset='.1' stop-color='%231a237e'/%3E%3Cstop offset='.27' stop-color='%231a237e'/%3E%3Cstop offset='.63' stop-color='%235c6bc0'/%3E%3Cstop offset='.88' stop-color='%235c6bc0'/%3E%3C/linearGradient%3E%3C/defs%3E%3Ctitle%3Ebackground-home%3C/title%3E%3Cpath d='M0 0v952.71l497.08 428.77c44.64 38.51 122.25 50 172.46 25.57L2600 467.26V0z' fill='url(%23a)'/%3E%3Cpath d='M624.43 1418.11c-44.33 4.25-94.67-9.44-127.35-37.63L0 952.48v50.82l452.08 389.18c44.61 38.52 122.13 50 172.35 25.63z' fill='%231a237e' opacity='.3'/%3E%3C/svg%3E");

  /*
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' viewBox='0 0 2600 1100'%3E%3Cdefs%3E%3ClinearGradient id='a' x1='-153' y1='642.88' x2='3129.11' y2='642.88' gradientUnits='userSpaceOnUse'%3E%3Cstop offset='.1' stop-color='%235c6bc0'/%3E%3Cstop offset='.27' stop-color='%235c6bc0'/%3E%3Cstop offset='.63' stop-color='%235c6bc0'/%3E%3Cstop offset='.88' stop-color='%231a237e'/%3E%3C/linearGradient%3E%3ClinearGradient id='b' y1='264.94' x2='3129.11' y2='264.94' xlink:href='%23a'/%3E%3C/defs%3E%3Cpath d='M2019.51 188.57c17.85 4.08 35.64 13 52.49 29.43l528 635.74v-66.18L2127 218c-34.41-33.57-72.76-35.77-107.49-29.43z' fill='%231a237e' opacity='.3'/%3E%3Cpath d='M1945 193L0 714v386h2600V853.33L2072 218c-41-40-87.59-35.47-127-25z' fill='url(%23a)'/%3E%3Cpath d='M2308.31 91c-60.24 17.09-77.23 69.94-37.76 117.43l210.94 253.81c26.74 32.19 72.83 54 118.51 59.37V8.25z' fill='url(%23b)'/%3E%3C/svg%3E");
    */
  background-repeat: no-repeat;
  background-color: transparent;
  position: relative;
  background-size: cover;
  background-position-x: center;
}
.section.footer {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' viewBox='0 0 2600 1100'%3E%3Cdefs%3E%3ClinearGradient id='a' x1='-153' y1='642.88' x2='3129.11' y2='642.88' gradientUnits='userSpaceOnUse'%3E%3Cstop offset='.1' stop-color='%23400D47'/%3E%3Cstop offset='.27' stop-color='%23511452'/%3E%3Cstop offset='.63' stop-color='%23B02BA6'/%3E%3Cstop offset='.88' stop-color='%23CB33BD'/%3E%3C/linearGradient%3E%3ClinearGradient id='b' y1='264.94' x2='3129.11' y2='264.94' xlink:href='%23a'/%3E%3C/defs%3E%3Cpath d='M2019.51 188.57c17.85 4.08 35.64 13 52.49 29.43l528 635.74v-66.18L2127 218c-34.41-33.57-72.76-35.77-107.49-29.43z' fill='%23cb33bd' opacity='.3'/%3E%3Cpath d='M1945 193L0 714v386h2600V853.33L2072 218c-41-40-87.59-35.47-127-25z' fill='url(%23a)'/%3E%3Cpath d='M2308.31 91c-60.24 17.09-77.23 69.94-37.76 117.43l210.94 253.81c26.74 32.19 72.83 54 118.51 59.37V8.25z' fill='url(%23b)'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-size: cover;
  padding-top: 340px;
}



/*
 Languages Switcher
*/




.ly-languages-switcher-link {
  /*margin: 0 0.2em;*/
  cursor: pointer;
  white-space: nowrap;
  text-decoration: none;
}

.ly-arrow {
  display: inline-block;
  position: relative;
  vertical-align: middle;
  -webkit-transition: transform 0.25s;
  -moz-transition: transform 0.25s;
  transition: transform 0.25s;
  vertical-align: middle;
  margin: 0 0.2em;
}
.ly-custom-dropdown-switcher.ly-is-open .ly-arrow {
  transform: rotate(180deg);
}
.ly-custom-dropdown-switcher.ly-arrow.ly-arrow-up {
  transform: rotate(180deg);
}
.ly-custom-dropdown-switcher.ly-is-open .ly-arrow.ly-arrow-up {
  transform: rotate(0deg);
}

/*
.ly-arrow.caret {
  border-top: 20px solid #fff;  
  border-right: 20px solid transparent;
  border-bottom: unset;
  border-left: 20px solid transparent;
  transform: rotate(0deg);
  animation: none !important;
}

.ly-arrow.stroke {
  border: solid white;
  display: inline-block;
  padding: 3px;
  transform: rotate(45deg);
  transform-origin: center center;
  animation: none !important;
}
.ly-is-open .ly-arrow.stroke,
.ly-arrow.stroke.ly-arrow-up {
  transform: rotate(225deg);
}
.ly-is-open .ly-arrow.stroke.ly-arrow-up {
  transform: rotate(45deg);
}
*/

.ly-custom-dropdown-switcher.ly-is-open .ly-custom-dropdown-list {
  display: inline-block !important;
}


.ly-custom-dropdown-switcher {
  text-align: left;
  display: inline-block;
  position: relative;
  user-select: none;
  cursor: pointer;
}
.ly-custom-dropdown-current {
  display: block;
  vertical-align: middle;
}
.ly-custom-dropdown-current-inner-image {
  margin-right: 0.5em;
}
.ly-custom-dropdown-current-inner-text {
  margin: 0 0.2em;
}
.ly-custom-dropdown-list {
  display: none !important;
  list-style: none;
  position: absolute;
  left: 0;
  padding: 0 !important;
  margin: 0 !important;
  z-index: 10;
  text-align: left;
  width: 100%;
  overflow: hidden;
}
.ly-custom-dropdown-list-element {
  color: inherit !important;
}
.ly-custom-dropdown-list > li {
  margin: 0;
  display: block;
}
.ly-custom-dropdown-list > li a {
  text-decoration: none;
}
.ly-custom-dropdown-list-element-left {
  margin-right: 0.5em;
}
.ly-custom-dropdown-list-element-right {
  text-align: left;
}
.ly-custom-dropdown-list-element-left,
.ly-custom-dropdown-list-element-right {
  /*vertical-align: middle;*/
}
.ly-languages-switcher-dropdown {
  display: inline-block;
}
.ly-languages-links-switcher {
  display: inline-block;
}
.ly-languages-switcher.ly-links a {
  display: inline-block;
}
.ly-languages-links-switcher > .ly-languages-link {
  margin-right: 0.5em;
  padding: inherit !important;
  color: inherit !important;
}
.ly-custom-dropdown-list-element {
  /*padding: inherit !important;*/
}
.ly-is-uppercase {
  text-transform: uppercase;
}
.ly-is-transparent.ly-links,
.ly-is-transparent.ly-list,
.ly-is-transparent.ly-currency-switcher,
.ly-is-transparent.ly-custom-dropdown-switcher,
.ly-is-transparent.ly-custom-dropdown-switcher .ly-custom-dropdown-list {
  background: transparent !important;
}

.ly-is-dropup .ly-custom-dropdown-list {
  bottom: 100%;
}
.ly-languages-switcher.ly-list {
  margin: 0;
  padding: 0;
  display: block;
}


.ly-languages-switcher {
  white-space: nowrap;
  display: inline-block;
  list-style-type: none;
}
ul.ly-languages-switcher {
  text-align: left;
  display: block;
}
select.ly-languages-switcher {
  padding: 0;
}

.ly-icon {
  margin-right: 0.2em;
}
.ly-switcher-wrapper.absolute { 
  position: absolute;
}
.ly-switcher-wrapper.top_left {
  top: 0;
  left: 0;
}
.ly-switcher-wrapper.top_right {
  top: 0;
  right: 0;
}
.ly-switcher-wrapper.bottom_left {
  bottom: 0;
  left: 0;
}
.ly-switcher-wrapper.bottom_right {
  bottom: 0;
  right: 0;
}
.ly-popup-modal .ly-switcher-wrapper {
  position: relative;
  margin: 0 !important;
}

textarea#custom-css {
  padding: 14px !important;
}



.ly-flag-icon {
  background-size: contain;
  background-position: 50%;
  background-repeat: no-repeat;
  position: relative;
  display: inline-block;
  width: 1.33333333em;
  line-height: 1em;
  margin: 0 0.2em;
}
.ly-flag-icon:before {
  content: '';
  display: inline-block;
}
.ly-flag-icon.ly-flag-squared {
  width: 1em;
}
.ly-flag-icon.ly-flag-rounded {
  width: 1em;
  border-radius: 50%;
}


.ly-currency-switcher {
  /*margin-left: .5em;*/
}

.ly-inner-text {
  margin: 0 0.2em;
}

.ly-popup-switcher-wrapper {
  position: absolute;
  z-index: 100;
  top: 50px;
  bottom: 50px;
  left: 50px;
  right: 50px;
}
.ly-popup-switcher {}
.ly-popup-switcher .ly-popup-current {
  cursor: pointer;
  background: transparent;
}
.ly-popup-switcher-modal {
  flex-direction: column;
  justify-content: center; 
  align-items: center;
  background:rgba(0, 0, 0, 0.38);
  display: flex;
  width: 100%;
  height: 100%;
}
.ly-popup-switcher-modal .ly-popup-modal-content {
  position: absolute;
  z-index: 200;
  top: auto;
  bottom: auto;
  left: auto;
  right: auto;
  padding: 1em;
  box-shadow: 0 0 20px rgba(0,0,0,.25);
}
.ly-popup-switcher-modal .ly-popup-modal-content .ly-languages-switcher-link {
  margin: 0;
}
.ly-popup-switcher-modal .ly-popup-modal-content ul {
  padding: 0;
  margin: 0;
  list-style: none;
}
.ly-popup-switcher-modal .ly-popup-modal-content ul .ly-languages-switcher-link {
  display: block;
  min-width: 225px;
  margin-top: 1em;
}
.ly-popup-switcher-modal .ly-popup-modal-content ul .ly-languages-switcher-link.current_lang {
  display: none;
}





.ly-popup-modal {
  /*
  position: absolute;
  z-index: 100;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  */
  display: none;
  flex-direction: column;
  justify-content: center; 
  align-items: center;
}
.ly-popup-modal.ly-is-open,
.ly-popup-current.ly-is-open {
  display: flex;
}
.ly-popup-modal-backdrop {
  position: absolute;
  top: 50px;
  bottom: 50px;
  left: 50px;
  right: 50px;
  background:rgba(0, 0, 0, 0.38);
}
.ly-popup-modal .ly-popup-modal-content {
  /*
  position: absolute;
  z-index: 200;
  top: auto;
  bottom: auto;
  left: auto;
  right: auto;
  */
  padding: 1em;
  box-shadow: 0 0 20px rgba(0,0,0,.25);
}

.ly-popup-modal .ly-popup-modal-content .ly-switcher-wrapper,
.ly-banner .ly-banner-content .ly-switcher-wrapper {
  position: relative !important;
  margin: 0 !important;
  display: inline-block !important;
}

.ly-banner .ly-recommendation-form button {
  margin-left: 10px;
}


.ly-banner {
  
}
.ly-banner .ly-banner-content {
  width: 100%;
  padding: 1em;
  box-shadow: 0 0 20px rgba(0,0,0,.25);
}

.recommendation.top { top: 0; left: 0; right: 0; margin-bottom: 0 !important; padding-bottom: 0 !important;}
.recommendation.bottom { bottom: 0; left: 0; right: 0; margin-top: 0 !important; padding-top: 0 !important;}
.recommendation.top_left { top: 0; left: 0; margin-right: 0 !important; margin-bottom: 0 !important; padding-right: 0 !important; padding-bottom: 0 !important;}
.recommendation.top_center { top: 0; left: 50%; transform: translateX(-50%); margin-right: 0 !important; margin-bottom: 0 !important; margin-left: 0 !important; padding-right: 0 !important; padding-bottom: 0 !important; padding-left: 0 !important;}
.recommendation.top_right { top: 0; right: 0; margin-left: 0 !important; margin-bottom: 0 !important; padding-left: 0 !important; padding-bottom: 0 !important; }
.recommendation.center_center { top: 50%; left: 50%; transform: translateX(-50%) translateY(-50%); margin: 0 !important; padding: 0 !important; }
.recommendation.bottom_left { bottom: 0; left: 0; margin-right: 0 !important; margin-top: 0 !important; padding-right: 0 !important; padding-top: 0 !important; }
.recommendation.bottom_center { bottom: 0; left: 50%; transform: translateX(-50%); margin-right: 0 !important; margin-left: 0 !important; margin-top: 0 !important; padding-right: 0 !important; padding-left: 0 !important; padding-top: 0 !important; }
.recommendation.bottom_right { bottom: 0; right: 0; margin-left: 0 !important; margin-top: 0 !important; padding-left: 0 !important; padding-top: 0 !important; }

.ly-close {
  position: absolute;
  opacity: 0.3;
  width: 1em;
  height: 1em;
  right: 1em;
  top: 1em;
  transform: translateX(-0.5em);
}
.ly-close:hover {
  opacity: 1;
}
.ly-close:before, .ly-close:after {
  position: absolute;
  left: 1em;
  content: ' ';
  height: 1em;
  width: 2px;
  background-color: #333;
}
.ly-close:before {
  transform: rotate(45deg);
}
.ly-close:after {
  transform: rotate(-45deg);
}



#root>div:not(.open-sidebar) #addCrossDomainLinksBar {
  left: 96px !important;
}


.stage-bg {
  background: rgba(102,102,102,1);
  background: -moz-radial-gradient(center, ellipse cover, rgba(102,102,102,1) 0%, rgba(51,51,51,1) 72%, rgba(34,34,34,1) 100%);
  background: -webkit-gradient(radial, center center, 0px, center center, 100%, color-stop(0%, rgba(102,102,102,1)), color-stop(72%, rgba(51,51,51,1)), color-stop(100%, rgba(34,34,34,1)));
  background: -webkit-radial-gradient(center, ellipse cover, rgba(102,102,102,1) 0%, rgba(51,51,51,1) 72%, rgba(34,34,34,1) 100%);
  background: -o-radial-gradient(center, ellipse cover, rgba(102,102,102,1) 0%, rgba(51,51,51,1) 72%, rgba(34,34,34,1) 100%);
  background: -ms-radial-gradient(center, ellipse cover, rgba(102,102,102,1) 0%, rgba(51,51,51,1) 72%, rgba(34,34,34,1) 100%);
  background: radial-gradient(ellipse at center, rgba(102,102,102,1) 0%, rgba(51,51,51,1) 72%, rgba(34,34,34,1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#666666', endColorstr='#222222', GradientType=1 );
}



.react-transform-component {
  height: 500px !important;
  width: unset !important;
  height: unset !important;
}
.react-transform-element {
  justify-content: center;
  width: unset !important;
  height: unset !important;
}


.custom-tooltip {
  background-color: #fff;
  padding: 12px;
  box-shadow: 0px 5px 5px -3px rgba(0,0,0,0.2),0px 8px 10px 1px rgba(0,0,0,0.14),0px 3px 14px 2px rgba(0,0,0,0.12);
}
.custom-tooltip .label {
  margin: 0;
  padding: 0;
}
.custom-tooltip .subfield {
  display: block;
}
.custom-tooltip .subfield.a {
  color: #5c6bc0;
}
.custom-tooltip .subfield.b {
  color: rgba(92, 107, 192, 0.7);
}


/* Banner Loop Tile */
#bannerLoop, .raise-height, .each-slide, .react-slideshow-container, .react-slideshow-wrapper.slide, .images-wrap {
  height: 100%;
  min-height: 250px;
}
.each-slide {
  position: relative;
}
#bannerLoop .indicators {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  opacity: 0;
  transition: all .3s ease-in;
}
#bannerLoop:hover .indicators {
  opacity: 1;
}
#bannerLoop .each-slideshow-indicator {
  background-color: rgba(204, 204, 204, 0);
  border: solid 1px rgb(204, 204, 204);
  width: 10px;
  height: 10px;
  margin: 12px 6px 12px 6px;
  padding: 4px;
}
#bannerLoop .each-slideshow-indicator.active {
  background-color: rgba(204, 204, 204, .45);
}
#bannerLoop .cta-btn {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
}
#bannerLoop .banner-label {
  position: absolute;
  top: 0;
  right: 0;
  padding: 2px 8px 2px 8px;
  font-size: .85rem;
  background-color: rgba(10, 10, 10, .75);
  opacity: 0;
  transition: all .3s ease-in;
}
#bannerLoop:hover .banner-label {
  opacity: .65;
  border-bottom-left-radius: 2px;
}


/* Cookie Compliant Banner */
.cc-window.cc-banner {
  z-index: 21474830000;
  overflow: visible;
  box-shadow: rgba(0,0,0,.35) 0 0 15px;
}
/*
.cc-message {
  padding-left: 120px;
}
.cc-message::before {
  content: ' ';
  width: 135px;
  height: 135px;
  background-image: url('/files/images/cookie_monster.png');
  background-size: contain;
  position: absolute;
  bottom: -5px;
  left: 0;
}
*/



.changelog {
  
}
.changelog div:first-child {
  flex: 0;
  min-width: 125px;
}
.changelog div:nth-child(3) {
}
.changelog h4 {
  margin-top: -12px;
}
.changelog h5 {
  margin-top: -8px;
}



.CodeMirror { height: 100%; }




.MuiListItem-root.Mui-selected .selected-label {
  display: none;
}

/**
 * Redactor
 */

 /*
.ly-editor {
  
}

.redactor-styles {
  border-radius: 4px;
  font-size: 14px;
  transition: padding-top 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms,padding-left 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms,border-color 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms,border-width 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms;
  transition-property: padding-top, padding-left, border-color, border-width;
  transition-duration: 200ms, 200ms, 200ms, 200ms;
  transition-timing-function: cubic-bezier(0, 0, 0.2, 1), cubic-bezier(0, 0, 0.2, 1), cubic-bezier(0, 0, 0.2, 1), cubic-bezier(0, 0, 0.2, 1);
  transition-delay: 0ms, 0ms, 0ms, 0ms;
}
.ly-editor.read-only .redactor-styles {
  color: rgba(0, 0, 0, 0.38);
  overflow: auto;
  cursor: no-drop;

}
.redactor-focus .redactor-styles {
  font-size: 14px;
}

textarea.redactor-source {
  padding-left: 14px !important;
  padding-right: 14px !important;
  font-size: 14px !important;
  border: none !important;
  border-radius: 0 !important;
  opacity: 1;
}
*/

.ly-editor._redactor textarea {
  width: 100%;
  height: 300px;
  border: solid 1px rgba(0, 0, 0, 0.26) !important;
  border-radius: 4px !important;
  padding: 12px 14px;
  font-size: 14px;
}
.ly-editor._redactor .redactor-box {
  border: solid 1px rgba(0, 0, 0, 0.26) !important;
  border-radius: 4px !important;
  transition: margin-top 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms,padding-left 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms,border-color 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms,border-width 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms;
  transition-property: margin-top, padding-left, border-color, border-width;
  transition-duration: 200ms, 200ms, 200ms, 200ms;
  transition-timing-function: cubic-bezier(0, 0, 0.2, 1), cubic-bezier(0, 0, 0.2, 1), cubic-bezier(0, 0, 0.2, 1), cubic-bezier(0, 0, 0.2, 1);
  transition-delay: 0ms, 0ms, 0ms, 0ms;
}
.ly-editor._redactor:not(.read-only) .redactor-box:hover {
  border: solid 1px rgba(0, 0, 0, 0.87) !important;
}
.ly-editor._redactor:not(.read-only) .redactor-focus.redactor-styles-on, 
.ly-editor._redactor:not(.read-only) .redactor-focus:focus.redactor-styles-on {
  border-color: #1a237e !important;
  border-width: 2px !important;
  margin-top: -2px;
}



.toolbar-rows-1 .redactor-focus .redactor-styles,
.redactor-source {
  padding-top: 46px !important;
}
.toolbar-rows-2 .redactor-focus .redactor-styles {
  padding-top: 78px !important;
}
.toolbar-rows-3 .redactor-focus .redactor-styles {
  padding-top: 110px !important;
}
.toolbar-rows-4 .redactor-focus .redactor-styles {
  padding-top: 142px !important;
}

.toolbar-wrapper {
  position: absolute;
  opacity: 0;
  padding: 8px;
}
.toolbar-wrapper .redactor-toolbar {
  opacity: 0;
  -webkit-transition: opacity 200ms linear;
     -moz-transition: opacity 200ms linear;
      -ms-transition: opacity 200ms linear;
       -o-transition: opacity 200ms linear;
          transition: opacity 200ms linear;
}
.toolbar-wrapper.in {
  opacity: 1;
}
.toolbar-wrapper.in .redactor-toolbar {
  opacity: 1;
}


.ly-editor.densed .toolbar-wrapper {
  padding: 0;
}
.ly-editor.densed .re-button {
  border-radius: 0 !important;
  font-size: 14px !important;
  padding: 8px 12px 5px 12px !important;
}
.ly-editor.densed .redactor-toolbar {
  padding: 8px 8px 0 8px;
}
.ly-editor.densed .redactor-styles {
  padding: 12px 14px;
}


.ly-editor.read-only .redactor-styles {
  background-color: #fafafa;
}
.ly-editor.read-only .redactor-styles * {
  color: rgba(0, 0, 0, 0.38) !important;
}
.ly-editor.read-only .toolbar-wrapper {
  display: none !important;
}


.ly-editor-label:before {
  content: '';
  position: absolute;
  z-index: -1;
  width: calc(100% + 4px);
  height: 1px;
  background-color: white;
  left: -2px;
  top: 8px;
}




/* Redactor */
.redactor-toolbar {
  display: flex !important;
  flex-direction: row;
  flex-wrap: wrap;
  z-index: 8;
}
.redactor-toolbar a:hover, .redactor-air a:hover {
  background: #1a237e;
}
.redactor-toolbar .re-fontsize { order: 1; }
.redactor-toolbar .re-button { order: 1; }
.redactor-toolbar .re-alignment { order: 2; }
.redactor-toolbar .re-fontcolor { order: 3; }
.redactor-toolbar .re-link { order: 4; }
.redactor-toolbar .re-table { order: 5; }
.redactor-toolbar .re-image { order: 6; }
.redactor-toolbar .re-video { order: 7; }
.redactor-toolbar .re-undo { order: 8; }
.redactor-toolbar .re-redo { order: 9; }
.redactor-toolbar .re-noeditor { order: 10; }
.source-mode .re-button {
  display: none;
}
.source-mode .re-button:hover {
  color: #fff;
}
.source-mode .re-button.re-html {
  display: inline-block;
}

.redactor-modal-box .redactor-modal-body {
  padding: 0px 24px 24px 24px !important;
}
.redactor-modal-box .redactor-modal-header {
  font-size: 1.25rem;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 300;
  line-height: 1.6;
  letter-spacing: 0.0075em;
  color: #1a237e;
  font-weight: 300;
  padding: 16px 24px;
  border: none;
}

.redactor-modal-tabs a:hover, .redactor-modal-tabs a.active {
  font-weight: 500;
  color: #1a237e;
  border-bottom-color: #1a237e;
}

.upload-redactor-box {
  border-color: #1a237e;
  border-width: 2px;
}

.ly-editor._redactor .redactor-modal button {
  color: #1a237e;
  border: 2px solid #1a237e;
}

.ly-editor._redactor .redactor-focus.redactor-styles-on, .redactor-focus:focus.redactor-styles-on {
  border: solid 1px rgba(0, 0, 0, 0.26) !important;
}

.ly-editor._redactor .redactor-modal-footer {
  text-align: center;
  padding: '16px 24px';
  border: none;
}
.ly-editor._redactor #redactor-overlay {
  background-color: rgba(255, 255, 255, 0.5);
  z-index: 10510;
}
.ly-editor._redactor .redactor-modal-box {
  z-index: 10510;
}
.ly-editor._redactor .redactor-modal-box .redactor-modal {
  border-radius: 4px;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 11px 15px -7px, rgba(0, 0, 0, 0.14) 0px 24px 38px 3px, rgba(0, 0, 0, 0.12) 0px 9px 46px 8px;
}




/* RedactorX */
.ly-editor._redactorx textarea {
  width: 100%;
  height: 300px;
  border: solid 1px rgba(0, 0, 0, 0.26) !important;
  border-radius: 4px !important;
  padding: 12px 14px;
  font-size: 14px;
}
.ly-editor._redactorx .rx-statusbar {
  padding: 4px 0px;
}
.ly-editor._redactorx .rx-content p {
  font-size: 14px;
}
.ly-editor._redactorx .rx-container {
  border-radius: 4px;
  border-color: rgba(0, 0, 0, 0.23);
}
.ly-editor._redactorx .rx-container .rx-editor .rx-content {
  padding: 12px 14px !important;
}
.ly-editor._redactorx .rx-form-container-flex .rx-form-button {
  white-space: nowrap;
}
.ly-editor._redactorx .rx-container.rx-in-focus .rx-editor,
.ly-editor._redactorx .rx-container.rx-in-focus .rx-source {
  padding-top: 47px !important;
}
.ly-editor._redactorx.read-only .rx-container.rx-in-focus .rx-editor {
  padding-top: 20px !important;
}
.ly-editor._redactorx.read-only .rx-container.rx-in-focus .rx-source {
  padding-top: 12px !important;
}
.ly-editor._redactorx:not(.read-only) .rx-container:hover {
  border-color: rgba(0, 0, 0, 0.87);
}
.ly-editor._redactorx:not(.read-only) .rx-container.focused,
.ly-editor._redactorx:not(.read-only) .rx-container.rx-in-focus {
  border-color: rgb(26, 35, 126);
  border-width: 2px;
  margin-top: -2px;
  margin-left: -0px;
}

.ly-richtext-editor-source .ly-editor._redactorx .rx-container {
  color: rgba(0, 0, 0, 0.38);
}
.ly-richtext-editor-target .ly-editor._redactorx .rx-container {
  color: rgba(0, 0, 0, 0.87);
}

.rx-editor [data-rx-type=embed].rx-block-focus, .rx-editor [data-rx-type=image].rx-block-focus, .rx-editor [data-rx-type=line].rx-block-focus, .rx-editor [data-rx-type=layer].rx-block-focus {
  outline: none !important;
}

.rx-form-container-flex .rx-form-button {
  word-break: normal;
}


/* Tool- & Topbars */
.ly-editor._redactorx .rx-toolbar-container {
  position: absolute;
  top: 2px !important;
  left: 2px;
  right: 2px;
  display: none;
}
.ly-editor._redactorx .rx-toolbar {
  height: 35px;
}
.ly-editor._redactorx .rx-button-toolbar {
  width: 35px;
  min-width: 35px;
  height: 35px;
}
.ly-editor._redactorx .rx-container.rx-in-focus .rx-toolbar-container {
  display: flex;
}
.ly-editor.source-mode .rx-toolbar-container .rx-toolbar {
  display: none;
}

.rx-toolbar .rx-button[data-name="add"],
.rx-toolbar .rx-button[data-name="underline"] {
  position: relative;
}
.rx-toolbar .rx-button[data-name="add"]::after,
.rx-toolbar .rx-button[data-name="underline"]::after {
  position: absolute;
  content: '';
  width: 1px;
  height: 31px;
  right: -1px;
  top: 2px;
  background-color: rgba(0, 11, 29, 0.15);
}

.rx-topbar .rx-button[data-name="html"] {
  margin-left: auto;
}
.rx-button-topbar {
  height: 35px;
}


/* RedactorX Fontcolor Plugin */
.rx-popup-fontcolor .rx-popup-body {
  display: flex;
  flex-direction: column-reverse;
}
.rx-popup-fontcolor .tab {
  display: none;
  margin: 0;
  padding: 0 8px 8px 8px;
  order: 1;
}
.rx-popup-fontcolor .tab.in {
  display: flex;
  flex-direction: column-reverse;
}
.rx-popup-fontcolor .tab input {
  width: 100%;
  margin-top: 8px;
  border: solid 1px #dedede;
  border-radius: 4px;
  padding: 8px;
}
.rx-popup-fontcolor .color-picker__dialog--static {
  box-shadow: none;
  display: inline-block;
  left: 0 !important;
  position: static;
  top: 0 !important;
  vertical-align: middle;
}
.rx-popup-fontcolor .rx-popup-items {
  display: flex;
  justify-content: space-between;
  order: 2;
}
.rx-popup-fontcolor .rx-popup-items .rx-popup-item {
  flex: 1;
  text-align: center;
  white-space: nowrap;
}
.color-picker__controls {
  border: solid 1px #dedede;
  border-radius: 4px;
}



.rx-popup-imageposition form.rx-popup-form,
.rx-popup-stack-alignment form.rx-popup-form {
  padding: 0;
}
.rx-popup-imageposition .rx-popup-form .rx-form-item,
.rx-popup-stack-alignment .rx-popup-form .rx-form-item {
  padding: 8px;
}
.rx-popup-imageposition .rx-popup-form .rx-form-item,
.rx-popup-stack-alignment .rx-popup-form .rx-form-item .rx-form-label {
  display: none;
}


/* Codemirror */
.rx-in-focus .CodeMirror-lines {
  margin-top: 35px !important;
}
.CodeMirror-readonly .CodeMirror-cursor {
  display: none !important
}




#render-props-popover {
  z-index: 15000 !important;
}


.sortableHelper {
  z-index: 100000;
  width: 100%;
  display: block;
}




/* Bulk Translation Feature */
#BulkTranslationsConfigurationPanel .MuiStepLabel-label {
  font-size: 1rem !important;
}
#BulkTranslationsConfigurationPanel .MuiStepLabel-label.MuiStepLabel-completed {
  color: green !important;
  font-weight: 400 !important;
}
#BulkTranslationsConfigurationPanel .MuiStepIcon-root.MuiStepIcon-completed {
  color: green;
}
#BulkTranslationsConfigurationPanel .MuiStepConnector-lineVertical {
  min-height: 0 !important;
  border-left-width: 0 !important;
}
#BulkTranslationsConfigurationPanel .MuiCheckbox-root {
  padding: 4px;
}
#BulkTranslationsConfigurationPanel .MuiFormControlLabel-root {
  margin-left: 0;
}
/*
#BulkTranslationsConfigurationPanel .MuiTreeItem-content .MuiTreeItem-label {
  border-bottom: solid 1px rgba(255,255,255,.25);
}
#BulkTranslationsConfigurationPanel .MuiTreeItem-root.Mui-selected > .MuiTreeItem-content .MuiTreeItem-label:hover {
  background-color: rgba(26, 35, 126, 0.12);
}
#BulkTranslationsConfigurationPanel .MuiTreeItem-root.Mui-selected:focus > .MuiTreeItem-content .MuiTreeItem-label {
  background-color: rgba(26, 35, 126, 0.08);
}
#BulkTranslationsConfigurationPanel .MuiTreeItem-root.Mui-selected > .MuiTreeItem-content .MuiTreeItem-label {
  background-color: lightgray;
  border-bottom: solid 1px rgba(255,255,255,.25);
}
*/



.swiper {
  overflow: visible;
}